// get updated date on footer
document.getElementById("year").textContent = new Date().getFullYear();

// make post cards all clickable
const cards = document.querySelectorAll(".home-work--grid-item");

cards.forEach((card) => {
  const mainLink = card.querySelector(".main-link");
  const clickableElements = Array.from(card.querySelectorAll("a"));
  // Stop propagation on any clickable elements within the card
  clickableElements.forEach((ele) =>
    ele.addEventListener("click", (e) => e.stopPropagation())
  );

  function handleClick(event) {
    const noTextSelected = !window.getSelection().toString();

    if (noTextSelected) {
      window.location.href = mainLink.href; // Navigate directly to the main link's href
    }
  }

  // Attach click event to the entire card
  card.addEventListener("click", handleClick);
});

// ga tracking events
const links = [
  { selector: "#resume-link", label: "resume-looked" },
  { selector: "#email-link", label: "email-looked" },
  { selector: "#talk-link", label: "talk-looked" },
  { selector: "#linkedin-link", label: "linkedin-looked" },
];

document.addEventListener("DOMContentLoaded", function () {
  function addTrackingEvent(link) {
    const element = document.querySelector(link.selector);
    if (element) {
      // Check if the element exists
      element.addEventListener("click", function () {
        gtag("event", "click", {
          event_category: "Portfolio Links",
          event_label: link.label,
        });
      });
    }
  }

  links.forEach(addTrackingEvent);
});
